<Side-test>
   <div class="side-menu-card" id="translation-tutorial-container">
                    <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close-side">
                    <div id="translation-tutorial-subcontainer">
                      
                      <!--
                        <div v-for="trans_tut_card in player_settings.translation_tutorial" :key="trans_tut_card.name" class="item-menu-translation">
                            <div class="side-menu-content">
                                <h3>{ (trans_tut_card?.title) }</h3>
                                <p>{ (trans_tut_card?.description) }</p>
                         
                            </div>
                        </div>
-->   
<div each="{ trans_tut_card in props.message.translation_tutorial }" class="item-menu-translation">
    <div class="side-menu-content">
      <h3>{ trans_tut_card.title }</h3>
      <p>{ trans_tut_card.description }</p>
    </div>
  </div>
                        <div class="btns-action">
                            <button class="prevBtn"><i class="fa fa-arrow-left"></i>{ ("Prev") }</button>
                            <button class="nextBtn">{ ("Next") }<i class="fa fa-arrow-right"></i></button>
                            <button style="display: none;" class="doneBtn">{ ("Done") }<i
                                    class="fa fa-check"></i></button>
                        </div>

                    </div>



                </div>
                <div class="side-menu-card" id="tutorial-menu">
                    <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close-side">

                    <h3>{ (props.message?.menu_tutorial_description) }</h3>

                    <div class="btns-about-bottom info-config">
                        <button class="btn" id="tutorial-info-btn">
                            <!-- <img src={loadFile(props.message?.button_info_icon)} class="tamkin-logo-bg">{ (props.message.button_info_text) } -->
                            <img src={loadFile(props.message?.button_info_icon)}>{ (props.message?.button_info_text) }
                        </button>
                        <button class="btn" id="tutorial-config-btn">
                            <img src={loadFile(props.message?.button_config_icon)}> {
                            (props.message?.button_config_text) }
                        </button>

                    </div>



                </div>

                <div class="side-menu-card" id="tutorial-config">
                    <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close-side">
                    <div v-for="config_tut_card in player_settings?.translation_tutorial" :key="config_tut_card.name" class="item-menu-config">
                        <div class="side-menu-content">
                            <h3>{ (config_tut_card?.title) }</h3>
                            <p>{ (config_tut_card?.description) }</p>
                        </div>
                    </div>
                    <div class="btns-action">
                        <button class="prevBtn"><i class="fa fa-arrow-left"></i>{ ("Prev") }</button>
                        <button class="nextBtn">{ ("Next") }<i class="fa fa-arrow-right"></i></button>
                        <button style="display: none;" class="doneBtn">{ ("Done") }<i
                                class="fa fa-check"></i></button>
                    </div>



                </div>

                <div class="side-menu-card" id="tutorial-info">
                    <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close-side">
                    <div v-for="info_tut_card in player_settings?.translation_tutorial" :key="info_tut_card.name" class="item-menu-info">
                        <div class="side-menu-content">
                            <h3>{ (info_tut_card?.title) }</h3>
                            <p>{ (info_tut_card?.description) }</p>
                        </div>
                    </div>

                    <div class="btns-action">
                        <button class="prevBtn"><i class="fa fa-arrow-left"></i>{ ("Prev") }</button>
                        <button class="nextBtn">{ ("Next") }<i class="fa fa-arrow-right"></i></button>
                        <button style="display: none;" class="doneBtn">{ ("Done") }<i
                                class="fa fa-check"></i></button>
                    </div>



                </div>


                <div class="side-menu-card" id="warning-menu">
                    <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close-side">
                    <div class="side-menu-content">
                        <h3 class="mb-3">{ (props.message?.warning_title) }</h3>
                        <p>{ (props.message?.warning_description) }</p>
                    </div>
                    <div class="warning-actions">
                        <div class="btns-action">
                            <button class="text-main" id="yes">{ (props.message?.warning_inform_yes) }</button>
                            <button id="no">{ (props.message?.warning_inform_no) }</button>
                        </div>
                    </div>
                </div>

                <div class="side-menu-card" id="asl-translate-menu">
                    <img src={loadFile('/assets/tamkin_website/img/close.png')} class="icon-close-side">
                    <div class="side-menu-content">
                        <h3 class="mb-3">{ (props.message?.asl_title) }</h3>
                        <p>{ (props.message?.asl_description) }</p>
                    </div>
                    <div class="asl-actions">
                        <div class="btns-action">
                            <button class="text-main repeat"><i class="fa fa-repeat"></i></button>
                        </div>
                    </div>
                </div>
  
 
 
  <script >
   import $ from 'jquery';
          export default { 
                 loadFile(path) {
                    return 'https://tamkin.app' + path;
                 },
                       onMounted() {

                 $(".icon-close-side").on("click", function () {
    console.log("ok ");
    console.log( $(this).closest('.side-menu-card'))
    $(this).closest('.side-menu-card').removeClass('active');
    // $("#player-card #links-container").removeClass("active");
    // $('#links-container .menu-link').removeClass("active");
    $('#start-menu').off();
});
                       }
          }

  </script>
  

</Side-test>
